import React from 'react'
import { Link } from 'gatsby'
import Html from './html'
import ProductTitle from './product-title'
import LazyImg from './lazy-img'
import BuyButton from './buy-button'
import useOptions from '../hooks/use-options'

function shortDescriptionHtml (descriptionHtml) {
  const closingP = '</p>'
  const index = descriptionHtml.toLowerCase().indexOf(closingP)
  if (index < 10) {
    return descriptionHtml
  }
  return descriptionHtml.slice(0, index + closingP.length)
}

function getDescription (descriptionHtml) {
  let result = shortDescriptionHtml(descriptionHtml)
  if (!result) {
    return descriptionHtml
  }
  return result
}

export default function Product ({ product }) {
  const { store: { learnMoreLabel } } = useOptions()

  const {
    title,
    slug,
    content,
    featuredImage
  } = product

  const link = `/shop/${slug}/`

  return (
    <section id={slug} className='Card Product-card'>
      <div className='Product Product--secondary Product--tease u-imgHover'>
        <Link to={link}>
          <div className='Product-image u-mediaContainer'>
            <LazyImg
              src={featuredImage.mediaItemUrl}
              alt={title}
            />
          </div>
        </Link>
        <div className='Product-info'>
          <div className='Product-content'>
            <div>
              <Link to={link}>
                <ProductTitle title={title} />
              </Link>
              {/*
                <ProductPrice variant={variant} available={available} />
              */}
              <Html className='Product-description Page-content'>
                {getDescription(content)}
              </Html>
            </div>
          </div>
          <div className='Product-buttons'>
            <Link className='Button--underline' to={link}>
              {learnMoreLabel}
            </Link>
            <BuyButton />
            {/* {!available && (
              <h5 className='Product-outstock' style={{ lineHeight: 1.6 }}>
                Out of stock
              </h5>
            )} */}
          </div>
        </div>

      </div>
      {/*
      <Json object={product} />
      */}
    </section>
  )
}
