import React from 'react'
import Product from './product'
import Slider from 'react-slick'
import useIsMobile from '../hooks/use-is-mobile'

function Wrapper ({ children }) {
  const mobile = useIsMobile()

  if (mobile) {
    return (
      <div className={`Cards`}>
        <Slider
          arrows={false}
          dots
          dotsClass='Carousel-nav'
          autoplay
        >
          {children}
        </Slider>
      </div>
    )
  }

  return (
    <section className={`u-sectionPadded`}>
      <div className={`Cards`}>
        {children}
      </div>
    </section>
  )
}

export default function CollectionCards ({ collection }) {
  return (
    <Wrapper>
      {collection.products.map(({ product }) => (
        <Product
          key={product.slug}
          product={product}
        />
      ))}
    </Wrapper>
  )
}
