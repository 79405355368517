import React from 'react'
import { useDispatch } from 'react-redux'
import useDistro from '../hooks/use-distro'
import { openDistro } from '../actions/ui'
import useOptions from '../hooks/use-options'

export default function BuyButton({ button }) {
  const { store: { buyButtonLabel } } = useOptions()
  const dispatch = useDispatch()
  const distros = useDistro()

  function onBuy() {
    dispatch(openDistro(true))
  }

  const className = button ? 'Button Button--shop' : 'Button--underline'

  if (distros.length === 1) {
      const { url } = distros[0]
      return (
        <a
            className={className}
            href={url}
            target='_blank'
            rel='noopener noreferrer'
        >
            {buyButtonLabel}
        </a>
      )
  }

  return (
    <button className={className} onClick={onBuy}>
      {buyButtonLabel}
    </button>
  )
}
