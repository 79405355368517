import React from 'react'
// import { currencySymbols } from '../utils/format-money'

export default function ProductTitle ({ title, heading }) {
  // let productTitle = title
  // if (productTitle.includes('£')) {
  //   productTitle = productTitle.replace('£', currencySymbols[variant.priceV2.currencyCode])
  // }

  if (heading) {
    return (
      <h1 className='h1--sm'>{title}</h1>
    )
  }

  return (
    <h3>{title}</h3>
  )
}
